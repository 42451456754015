.taglineWrapper {
  margin: 8px auto 0;
}

.header {
  transition: 0.3s max-height ease-in-out, 0.3s min-height ease-in-out;
  position: relative;
  overflow: hidden;
  display: flex;
  justify-content: space-between;
  min-height: 19px;
  max-height: 52px;
}

.standardHeader {
  transition: 0.3s transform ease-in-out;
}

.collapsedHeader {
  position: absolute;
  top: 52px;
  width: 100%;
  transition: 0.3s transform ease-in-out;
}

.closeWrapper {
  margin-left: 16px;
}

.closeWrapper:hover {
  cursor: pointer;
}

.headerBody {
  overflow: hidden;
}

.collapsed {
  min-height: 28px;
  max-height: 28px;
}

.collapsed .standardHeader,
.collapsed .collapsedHeader {
  transform: translateY(-52px);
}

.headerLogoRow {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.cashLogoWrapper {
  margin-left: 4px;
}
