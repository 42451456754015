.container {
  margin: 0 auto;
  max-width: 425px;
  display: flex;
  flex-direction: column;
  z-index: 1;
  height: auto;
  padding: 1.5rem 0.5rem;
}

.desktop {
  padding-top: 64px;
}

.light {
  width: 100%;
  position: relative;
  flex: 1;
}

.original {
  width: 100%;
  height: 100%;
}
